import { Slider } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ widthPercent: number }>`
    position: absolute;
    z-index: 100;
    width: ${(props) => props.widthPercent}%;
    left: ${(props) => 50 - props.widthPercent / 2}%;
    top: 90%;
`;

export const PathfinderSlider: FC<{
    startDate: number;
    endDate: number;
    minDate: number;
    maxDate: number;
    setFilterStartDate: (value: number) => void;
    setFilterEndDate: (value: number) => void;
}> = ({ startDate, endDate, minDate, maxDate, setFilterStartDate, setFilterEndDate }) => {
    const handleSliderChange = (e: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setFilterStartDate(newValue[0]);
            setFilterEndDate(newValue[1]);
        }
    };
    return (
        <Wrapper widthPercent={30}>
            <div style={{ backgroundColor: 'lightblue' }}>
                <Slider value={[startDate, endDate]} min={minDate} max={maxDate} onChange={handleSliderChange} />
            </div>
        </Wrapper>
    );
};
