import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Routes, Route, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Home from './pages/Front/Home';
import { Terms } from './pages/Terms';
import AboutUs from './pages/AboutUs';
import Admin from './pages/Admin';
import UserPanel from './pages/UserPanel';
import OverwatchReports from './pages/OverwatchReports';
import PathfinderReports from './pages/PathfinderReports';
import OrderOverwatchReport from './pages/Report/Build/OrderOverwatchReport';
import OrderPathfinderReport from './pages/Report/Build/OrderPathfinderReport';
import Careers from './pages/Careers';
import styled from 'styled-components';
import { Login } from './auth/Login';
import { PathfinderReport } from './pages/Report/interactiveReport/PathfinderReport';
import OrderReport from './pages/Report/Build/OrderReport';
import { getConsentCookie } from './utils/cookies';

import Footer from './shared/components/Footer';
import { CookieConsent } from './shared/components/CookieConsent';

import Navbar from './shared/components/Navbar';
import { AuthProvider } from './auth/AuthProvider';
import { ProtectedContent } from './auth/ProtectedContent';
import { ReportContent } from './auth/ReportContent';
import { InteractiveReport } from './pages/Report/interactiveReport/InteractiveReport';
import StoryTeller from './shared/components/storytelling/Storyteller';
import { CardPage } from './pages/Card';
import VisualizeCandidates from './pages/VisualizeCandidates';
import ClearViewReport from './pages/ClearViewReport';
import SearchComponent from './pages/SearchComponent';
import PressReleasePage from './pages/PressRelease';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Content = styled.div``;

function App() {
    const [visible, setVisibility] = useState(true);
    const [consented, setConsented] = useState(getConsentCookie());

    const location = useLocation();

    return (
        <QueryClientProvider client={queryClient}>
            <CookieConsent
                visible={visible}
                setVisibility={setVisibility}
                consented={consented}
                setConsented={setConsented}
            />
            <Content>
                <AuthProvider>
                    <Navbar visible={visible} setCookieVisibility={setVisibility} consented={consented} />
                    <SentryRoutes>
                        <Route path="/report-builder" element={<OrderReport />} />
                        <Route path="/privacy-policy" element={<Terms />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/careers" element={<Careers />} />
                        <Route path="/card" element={<CardPage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/press-release" element={<PressReleasePage />} />
                        <Route element={<ProtectedContent />}>
                            <Route path="/overwatch-reports" element={<OverwatchReports />} />
                            <Route path="/pathfinder-reports" element={<PathfinderReports />} />
                            <Route path="/order-overwatch-report" element={<OrderOverwatchReport />} />
                            <Route path="/order-pathfinder-report" element={<OrderPathfinderReport />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/visualize_candidates" element={<VisualizeCandidates />} />
                            <Route path="/clearview" element={<ClearViewReport />} />
                            <Route element={<ReportContent />}>
                                <Route path="/story/:id" element={<StoryTeller />} />
                                <Route path="/pathfinder/:id" element={<PathfinderReport />} />
                                <Route path="/demo/pathfinder/:id" element={<PathfinderReport />} />
                                <Route path="/overwatch/:id" element={<InteractiveReport />} />
                                <Route path="/demo/overwatch/:id" element={<InteractiveReport />} />
                            </Route>
                            <Route path="/search" element={<SearchComponent />} />
                            <Route path="/user" element={<UserPanel />} />
                        </Route>
                        <Route path="/report/" element={<InteractiveReport />} />
                        <Route path="*" element={<Home />} />
                    </SentryRoutes>
                    {!(
                        location.pathname.startsWith('/report') |
                        location.pathname.startsWith('/story') |
                        location.pathname.startsWith('/pathfinder') |
                        location.pathname.startsWith('/overwatch') |
                        location.pathname.startsWith('/visualize_candidates') |
                        location.pathname.startsWith('/admin/order-pathfinder-report') |
                        location.pathname.startsWith('/order-overwatch-report') |
                        location.pathname.startsWith('/clearview')
                    ) && <Footer />}
                </AuthProvider>
            </Content>
            <ReactQueryDevtools initialIsOpen position="top-left" />
        </QueryClientProvider>
    );
}

export default App;
