import React, { useRef, useEffect, useState } from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import styled from 'styled-components';
import { PathfinderData } from '../../utils/interfaces';
import { ReportType } from '../../utils/enums';
import {
    addGapPopup,
    addMouseClickAisGapEffect,
    addMouseMoveHoverEffect,
    addTargetsOfInterestPopup,
} from '../../utils/map';
import { highlightFeature, ILayer, addFillLayer, addPopup } from './Map';
import constants from '../constants';
import { Feature, FeatureCollection } from 'geojson';
import 'mapboxgl-legend/dist/style.css';
import { useParams } from 'react-router-dom';
import { IChosenBoatObject } from '../../pages/Report/interactiveReport/PathfinderReport';
import { AddDougaMapLayers } from './DougaCaseUtils';

const MapContainer = styled.div<{ isLoadingData: Boolean }>`
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isLoadingData ? 0.5 : 1)};
`;

const PathfinderMap: React.FC<{
    containerRef: React.RefObject<HTMLDivElement>;
    pathfinderData: PathfinderData | undefined;
    reportMetadata: any | undefined;
    isDataLoading: Boolean;
    boatMetaData: Feature | null;
    setChosenBoatObject: (value: IChosenBoatObject | null) => void;
    hoveredPathElement: number | null;
    filterStartDate: number;
    filterEndDate: number;
}> = ({
    containerRef,
    pathfinderData,
    reportMetadata,
    isDataLoading,
    boatMetaData,
    setChosenBoatObject,
    hoveredPathElement,
    filterStartDate,
    filterEndDate,
}) => {
    const [currMap, setMap] = useState<undefined | mapboxgl.Map>(undefined);
    const popupRef = useRef(new mapboxgl.Popup({ offset: 15 }));
    const gapsPopupsRef = useRef(new mapboxgl.Popup({ offset: 20 }));
    const params = useParams();
    const isDougaCase: boolean = params.id?.split('_')[0] === '352002013';

    useEffect(() => {
        if (containerRef.current && reportMetadata && !currMap) {
            let coords = {
                lat: 57.33870347771735,
                lng: 2.042840975438542,
            };
            let zmm = 6;
            if (params.id) {
                let center_pos = reportMetadata.metadata.center_pos;
                coords = {
                    lat: center_pos[1],
                    lng: center_pos[0],
                };
                zmm = reportMetadata.metadata.zoom;
            }
            mapboxgl.accessToken =
                'pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw';
            const maps: mapboxgl.Map = new mapboxgl.Map({
                container: containerRef.current,
                center: coords,
                zoom: zmm,
                style: 'mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms',
            });

            //Broadcast loaded
            maps.on('load', () => {
                maps.loadImage('/images/icons/ArrowRight.png', (error: any, image: any) => {
                    if (error) {
                        throw error;
                    }
                    maps.addImage('arrow_right', image);
                });
                setMap(maps);
                maps.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
                maps.addControl(new mapboxgl.ScaleControl({ maxWidth: 500 }));
            });
        }
    }, [currMap, containerRef, reportMetadata, params]);

    // useEffect for adding layers to map
    useEffect(() => {
        const setHiglightedFeatureVisibility = (visible: boolean) => {
            setLayerVisibility(currMap!, visible, 'selectedCircle');
        };
        if (currMap && pathfinderData && reportMetadata) {
            pathfinderData.cloudmasks && addCloudmasksLayer(currMap, pathfinderData);
            pathfinderData.possibility_area_in_gap_at_datatake_sensing_time &&
                addPossibilityAreaInGapAtSensingTimeLayer(currMap, pathfinderData);
            pathfinderData.oil_pipelines && addOilPipelinesLayer(currMap, pathfinderData);
            pathfinderData.ais_tracks && addAisTracksLayer(currMap, pathfinderData, filterStartDate);
            pathfinderData.ais_gaps && addAisGapsLayer(currMap, pathfinderData, gapsPopupsRef, popupRef);
            pathfinderData.ais_gaps_with_images && addAisGapsWithImagesLayer(currMap, pathfinderData, popupRef);
            pathfinderData.sar_detections && addTargetsOfInterestLayerToMap(currMap, pathfinderData, popupRef);
            addMapLayers(currMap, pathfinderData, popupRef, setHiglightedFeatureVisibility, setChosenBoatObject);
            if (isDougaCase && !currMap.getLayer('douga_source')) {
                AddDougaMapLayers(currMap);
            }
        }

        // Fly to first satellite detection if avaliable, else fly to first ais message
        if (
            currMap &&
            pathfinderData &&
            pathfinderData.ais_positions &&
            pathfinderData.ais_positions.features[0].geometry.type === 'Point'
        ) {
            const endCoordinates = pathfinderData.ais_positions.features[0].geometry.coordinates;
            const end = { center: { lng: endCoordinates[0], lat: endCoordinates[1] }, pitch: 0, bearing: 0 };
            currMap?.flyTo({ ...end, duration: 2022, essential: true });
        } else if (
            currMap &&
            pathfinderData &&
            pathfinderData.ais_tracks &&
            pathfinderData.ais_tracks.features[0].geometry.type === 'LineString'
        ) {
            const endCoordinates = pathfinderData.ais_tracks.features[0].geometry.coordinates;
            const end = { center: { lng: endCoordinates[0][0], lat: endCoordinates[0][1] }, pitch: 0, bearing: 0 };
            currMap?.flyTo({ ...end, duration: 2022, essential: true });
        }
    }, [currMap, pathfinderData, reportMetadata]);

    useEffect(() => {
        if (currMap) {
            const timeRangeFilter = [
                'all',
                ['>=', ['to-number', ['get', 'start_time_in_miliseconds']], filterStartDate],
                ['<=', ['to-number', ['get', 'end_time_in_miliseconds']], filterEndDate],
            ];
            currMap.setFilter('ais_tracks_pathfinder', timeRangeFilter);
            currMap.setFilter('ais_tracks_pathfinder_hover', timeRangeFilter);
            currMap.setFilter('ais_track_arrows_pathfinder', timeRangeFilter);
            currMap.setFilter('ais_gaps_pathfinder', timeRangeFilter);
            currMap.setFilter('ais_gaps_pathfinder_hover', timeRangeFilter);
            currMap.setFilter('ais_gaps_with_images_pathfinder', timeRangeFilter);
            currMap.setFilter('ais_gaps_with_images_pathfinder_hover', timeRangeFilter);
            if (currMap.getSource('estimated_ais_pathfinder')) {
                const newAisPositions: FeatureCollection = { type: 'FeatureCollection', features: [] };
                const features = pathfinderData?.ais_positions.features.filter(
                    (image) =>
                        image.properties?.timestamp_in_miliseconds > filterStartDate &&
                        filterEndDate > image.properties?.timestamp_in_miliseconds
                );
                newAisPositions.features = features ? features : [];
                (currMap.getSource('estimated_ais_pathfinder') as GeoJSONSource).setData(newAisPositions); // Filter this way because setFilter will warn of undefined in clustering calculation in the other way
            }
        }
    }, [filterStartDate, filterEndDate]);

    useEffect(() => {
        if (boatMetaData && boatMetaData.geometry.type === 'Point') {
            const coordinates = boatMetaData.geometry.coordinates;
            currMap?.flyTo({ center: { lon: coordinates[0], lat: coordinates[1] }, duration: 2700 });
        }
    }, [currMap, boatMetaData]);

    useEffect(() => {
        if (typeof hoveredPathElement === 'number') {
            if (pathfinderData && currMap) {
                const candidate = pathfinderData.ais_positions.features[hoveredPathElement];
                const tempFeature: mapboxgl.MapboxGeoJSONFeature = {
                    layer: { id: 'selectedCircle', type: 'circle' },
                    source: 'selectedCircle',
                    sourceLayer: 'selectedCircle',
                    state: { hover: true },
                    geometry: candidate.geometry,
                    properties: candidate.properties,
                    type: candidate.type,
                };
                highlightFeature(currMap, tempFeature, 'orange', (hei: boolean) => true);
            }
        } else {
            if (currMap && typeof currMap.getLayer('selectedCircle') !== 'undefined') {
                currMap.removeLayer('selectedCircle');
                currMap.removeSource('selectedCircle');
            }
        }
    }, [currMap, hoveredPathElement]);

    return (
        <>
            <MapContainer ref={containerRef} isLoadingData={isDataLoading} />
        </>
    );
};

export default PathfinderMap;

const hasGapIx = (features: Feature[]) => {
    return features.length > 0 && 'gap_ix' in features[0].properties!;
};

const addMapLayers = (
    currMap: mapboxgl.Map,
    insights: PathfinderData,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    setHiglightedFeatureVisibility: (visible: boolean) => void,
    setChosenBoatObject: (value: IChosenBoatObject | null) => void
) => {
    let insight_id = insights!.id;
    let colors = constants.colors.markers;
    let layers: ILayer[] = [
        {
            id: `undetected_ais_${insight_id}`,
            name: 'undetected_ais',
            date: insight_id,
            data: insights!.undetected_ais,
            fill: colors.undetectedAis.fill,
            filter: hasGapIx(insights!.undetected_ais.features) ? ['==', 'gap_ix', ''] : undefined,
            stroke: colors.undetectedAis.stroke,
            type: 'circle',
            popupType: 'detection',
            circleRadius: 3,
            cluster: false,
        },
        {
            id: `dark_vessels_${insight_id}`,
            name: 'dark_vessels',
            date: insight_id,
            data: insights!.dark_vessels,
            fill: colors.darkShips.fill,
            filter: hasGapIx(insights!.undetected_ais.features) ? ['==', 'gap_ix', ''] : undefined,
            stroke: colors.darkShips.stroke,
            type: 'circle',
            popupType: 'detection',
            cluster: false,
        },
        {
            id: `matched_vessels_${insight_id}`,
            name: 'matched_vessels',
            date: insight_id,
            data: insights!.matched_vessels,
            fill: colors.matchedVessels.fill,
            filter: hasGapIx(insights!.undetected_ais.features) ? ['==', 'gap_ix', ''] : undefined,
            stroke: colors.matchedVessels.stroke,
            type: 'circle',
            popupType: 'detection',
            cluster: false,
        },
        {
            id: `estimated_ais_${insight_id}`,
            name: 'estimated_ais',
            date: insight_id,
            data: insights.ais_positions,
            fill: [
                'match',
                ['get', 'image_source'],
                'sentinel1',
                'grey',
                'sentinel2',
                '#f0d971',
                'planet_scope',
                '#00A1FF',
                '#8de',
            ],
            stroke: '#000',
            type: 'circle',
            popupType: undefined,
            cluster: true,
        },
        {
            id: `static_objects_${insight_id}`,
            name: 'static_objects',
            date: insight_id,
            data: insights.static_objects!,
            fill: '#294868',
            stroke: '#000',
            type: 'circle',
            popupType: 'toi',
            cluster: false,
        },
        {
            id: `images_of_gaps_${insight_id}`,
            name: 'images_of_gaps',
            date: insight_id,
            static: true,
            data: insights.images_of_gaps!,
            fill: [
                'match',
                ['get', 'image_source'],
                'sentinel1',
                'grey',
                'sentinel2',
                '#f0d971',
                /* other */ '#00F5FF',
            ],
            filter: ['==', 'gap_ix', ''],
            fillOpacity: 0.3,
            type: 'fill',
            cluster: false,
        },
    ];
    layers.forEach((layer) => {
        if (layer.type === 'circle') {
            addCircleLayer(
                currMap!,
                layer.data,
                layer.id,
                layer.fill,
                layer.filter,
                layer.stroke!,
                popupRef,
                layer.circleRadius!,
                setHiglightedFeatureVisibility,
                setChosenBoatObject,
                layer.cluster,
                layer.popupType
            );
        } else if (layer.type === 'fill') {
            addFillLayer(currMap!, layer.data, layer.id, layer.fill, layer.fillOpacity!, layer.filter!);
        }
    });
};

const addCircleLayer = (
    currMap: mapboxgl.Map,
    data: any,
    id: string,
    fill: string,
    filter: string[] | undefined,
    stroke: string,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    circleRadius: number = 4,
    setHiglightedFeatureVisibility: (visible: boolean) => void,
    setChosenBoatObject: (value: IChosenBoatObject | null) => void,
    cluster?: boolean,
    popupType?: 'detection' | 'toi',
    staticObj?: boolean
) => {
    if (currMap && !currMap.getSource(id)) {
        const point_hover = ['all', ['==', ['feature-state', 'hover'], true]];
        // debugger;
        let hoverId = `${id}_hover`;
        let layerDict: any = {
            id: id,
            type: 'circle',
            source: id,
            layout: {
                visibility: 'visible',
            },
            paint: {
                'circle-radius': cluster
                    ? ['step', ['get', 'point_count'], circleRadius, 1, circleRadius * 2, 7, circleRadius * 2.75]
                    : circleRadius,
                'circle-color': fill,
                'circle-stroke-color': ['case', point_hover, 'white', stroke],
                'circle-stroke-width': 1,
                'circle-opacity': 1,
            },
        };
        if (filter) {
            layerDict['filter'] = filter;
        }
        currMap.addSource(id, {
            type: 'geojson',
            data: data,
            cluster: cluster ? true : false,
            clusterMaxZoom: 10,
            clusterRadius: 12,
        });
        if (cluster) {
            currMap.addLayer({
                id: 'clusters',
                type: 'circle',
                source: id,
                filter: ['has', 'point_count'],
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        circleRadius,
                        1,
                        circleRadius * 2,
                        6,
                        circleRadius * 2.75,
                    ],
                    'circle-color': ['step', ['get', 'point_count'], '#f0d971', 1, '#fad060', 6, '#ffC060'],
                    'circle-stroke-color': ['case', point_hover, 'white', stroke],
                    'circle-stroke-width': 1,
                    'circle-opacity': 1,
                },
            });
            layerDict['filter'] = ['!', ['has', 'point_count']];
            currMap.addLayer(layerDict);
            currMap.addLayer({
                id: 'point-count',
                type: 'symbol',
                source: id,
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                },
            });
            currMap.on('click', 'clusters', (e) => {
                const features = currMap.queryRenderedFeatures(e.point, {
                    layers: ['clusters'],
                });
                const clusterId = features[0].properties?.cluster_id;
                const source: mapboxgl.GeoJSONSource = currMap.getSource(id) as mapboxgl.GeoJSONSource;
                source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;
                    if (features[0].geometry.type === 'Point') {
                        currMap.easeTo({
                            center: {
                                lon: features[0].geometry?.coordinates[0],
                                lat: features[0].geometry?.coordinates[1],
                            },
                            zoom: zoom,
                            duration: 1300,
                        });
                    }
                });
            });
        } else {
            currMap.addLayer(layerDict);
        }
        if (!staticObj) {
            currMap.addSource(hoverId, {
                type: 'geojson',
                data: data,
                generateId: true, // This ensures that all features have unique IDs
            });
            let hoverLayerObj: any = {
                id: hoverId,
                type: 'circle',
                source: hoverId,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 12, 3],
                    'circle-stroke-color': fill,
                    'circle-stroke-width': 2,
                    'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.2, 0],
                    'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0],
                    'circle-color': fill,
                },
                metadata: {
                    name: 'Satellites',
                },
            };
            if (filter) {
                hoverLayerObj['filter'] = filter;
            }
            currMap.addLayer(hoverLayerObj);

            let hoveredItem: number | null = null;

            currMap.on('mousemove', hoverId, (event: any) => {
                currMap.getCanvas().style.cursor = 'pointer';

                if (event.features.length === 0) return;

                if (hoveredItem) {
                    currMap.removeFeatureState({
                        source: hoverId,
                        id: hoveredItem,
                    });
                }

                hoveredItem = event.features[0].id;

                currMap.setFeatureState(
                    {
                        source: hoverId,
                        id: hoveredItem ? hoveredItem : 'hei',
                    },
                    {
                        hover: true,
                    }
                );
            });
            currMap.on('click', function (e) {
                var features = currMap.queryRenderedFeatures(e.point, { layers: [id] });
                if (!features.length) {
                    return;
                }
                var feature = features[0];
                if (feature.properties) {
                    const chosen: IChosenBoatObject = {
                        lat: feature.properties.lat,
                        lon: feature.properties.lon,
                        timestamp: feature.properties.timestamp,
                        image_path: feature.properties.img_path,
                        id: feature.id,
                    };
                    setChosenBoatObject(chosen);
                }
                highlightFeature(currMap, feature, fill, setHiglightedFeatureVisibility);
            });
            currMap.on('mouseleave', hoverId, () => {
                if (hoveredItem) {
                    currMap.setFeatureState(
                        {
                            source: hoverId,
                            id: hoveredItem,
                        },
                        {
                            hover: false,
                        }
                    );
                }
                hoveredItem = null;
                // Remove the information from the previously hovered feature from the sidebar

                // Reset the cursor style
                currMap.getCanvas().style.cursor = '';
            });
        }
        popupType &&
            addPopup(currMap, id, popupRef, ReportType.PathfinderReport, popupType, setHiglightedFeatureVisibility);
    }
};

const addTargetsOfInterestLayerToMap = (
    currMap: mapboxgl.Map,
    item: PathfinderData,
    popupRef: React.MutableRefObject<mapboxgl.Popup>
) => {
    let targets_of_interest_detection_id = `targets_of_interest_detection_${item.id}`;
    if (currMap && !currMap.getSource(targets_of_interest_detection_id)) {
        currMap
            .addSource(targets_of_interest_detection_id, {
                type: 'geojson',
                data: item.sar_detections,
            })
            .addLayer({
                id: targets_of_interest_detection_id,
                type: 'circle',
                source: targets_of_interest_detection_id,
                layout: {
                    // make layer visible by default
                    visibility: 'visible',
                },
                paint: {
                    'circle-radius': 6,
                    'circle-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        '#cc0000', // when hover
                        'red', // when normal
                    ],
                    'circle-stroke-color': '#000',
                    'circle-stroke-width': 2,
                    'circle-stroke-opacity': 0.2,
                },
            });

        addMouseMoveHoverEffect(currMap, targets_of_interest_detection_id);
        addTargetsOfInterestPopup(currMap, targets_of_interest_detection_id, popupRef, ReportType.PathfinderReport);
    }
};

const addAisTracksLayer = (currMap: mapboxgl.Map, item: PathfinderData, filterStartDate: number) => {
    let ais_tracks_id = `ais_tracks_${item.id}`;
    const ais_tracks_id_hover = ais_tracks_id + '_hover';
    if (currMap && !currMap.getSource(ais_tracks_id)) {
        currMap
            .addSource(ais_tracks_id, {
                type: 'geojson',
                data: item.ais_tracks,
            })
            .addLayer({
                // This layer is for listening to hovers, needs to have same name as source
                id: ais_tracks_id,
                type: 'line',
                source: ais_tracks_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-width': 20,
                    'line-opacity': 0,
                },
            })
            .addLayer({
                id: ais_tracks_id_hover,
                type: 'line',
                // filter: ['>=','start_timestamp',  ]
                source: ais_tracks_id,
                layout: {
                    // make layer visible by default
                    visibility: 'visible',
                },
                paint: {
                    'line-width': [
                        'case',
                        ['==', ['feature-state', 'hover'], true],
                        2, // when hover
                        1, // when normal
                    ],
                    'line-color': [
                        'case',
                        ['==', ['feature-state', 'hover'], true],
                        '#722fb1', // when hover
                        '#9671b8', // when normal
                    ],
                },
            })
            .addLayer({
                id: `ais_track_arrows_${item.id}`,
                type: 'symbol',
                source: ais_tracks_id,
                layout: {
                    'symbol-placement': 'line',
                    'icon-image': 'arrow_right',
                    'icon-size': 0.5,
                },
            });
        addMouseMoveHoverEffect(currMap, ais_tracks_id);
    }
};

const addAisGapsLayer = (
    currMap: mapboxgl.Map,
    item: PathfinderData,
    gapsPopupsRef: React.MutableRefObject<mapboxgl.Popup>,
    popupRef: React.MutableRefObject<mapboxgl.Popup>
) => {
    const ais_gaps_id = `ais_gaps_${item.id}`;
    const ais_gaps_id_hover = ais_gaps_id + '_hover';
    if (currMap && !currMap.getSource(ais_gaps_id)) {
        currMap
            .addSource(ais_gaps_id, {
                type: 'geojson',
                data: item.ais_gaps,
            })
            .addLayer({
                // This layer is for listening to hovers, needs to have same name as source
                id: ais_gaps_id,
                type: 'line',
                source: ais_gaps_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-width': 15,
                    'line-opacity': 0,
                },
            })
            .addLayer({
                id: ais_gaps_id_hover,
                type: 'line',
                source: ais_gaps_id,
                layout: {
                    // make layer visible by default
                    visibility: 'visible',
                },
                paint: {
                    'line-width': 3,
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], true],
                        '#e79325', // when hover e9a54b
                        '#e7a86c', // when normal
                    ],
                    'line-dasharray': [2, 2],
                },
            });

        addMouseMoveHoverEffect(currMap, ais_gaps_id, true, gapsPopupsRef);
        addGapPopup(currMap, ais_gaps_id, popupRef);
    }
};

const addAisGapsWithImagesLayer = (
    currMap: mapboxgl.Map,
    item: PathfinderData,
    popupRef: React.MutableRefObject<mapboxgl.Popup>
) => {
    const ais_gaps_with_images_id = `ais_gaps_with_images_${item.id}`;
    const ais_gaps_with_images_id_hover = ais_gaps_with_images_id + '_hover';
    if (currMap && !currMap.getSource(ais_gaps_with_images_id)) {
        currMap
            .addSource(ais_gaps_with_images_id, {
                type: 'geojson',
                data: item.ais_gaps_with_images,
            })
            .addLayer({
                // This layer is for listening to hovers, needs to have same name as source
                id: ais_gaps_with_images_id,
                type: 'line',
                source: ais_gaps_with_images_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-width': 30,
                    'line-opacity': 0,
                },
            })
            .addLayer({
                id: ais_gaps_with_images_id_hover,
                type: 'line',
                source: ais_gaps_with_images_id,
                layout: {
                    // make layer visible by default
                    visibility: 'visible',
                },
                paint: {
                    'line-width': 3,
                    'line-color': [
                        'case',
                        [
                            'all',
                            ['==', ['feature-state', 'itIsClicked'], true],
                            ['==', ['feature-state', 'hover'], true],
                        ], // layer hovered and clicked
                        '#e72d2d',
                        ['==', ['feature-state', 'hover'], true], // layer hover
                        '#e44a4a',
                        ['==', ['feature-state', 'itIsClicked'], true], // layer clicked,
                        '#e72d2d',
                        '#ec8e8e', // default
                    ],
                    'line-dasharray': [2, 2],
                },
            });

        addMouseMoveHoverEffect(currMap, ais_gaps_with_images_id);
        addMouseClickAisGapEffect(currMap, ais_gaps_with_images_id, [
            'undetected_ais_pathfinder',
            'images_of_gaps_pathfinder',
            'dark_vessels_pathfinder',
            'matched_vessels_pathfinder',
            'cloudmasks_pathfinder',
            'images_of_gaps_pathfinder',
            'possibility_area_in_gap_at_datatake_sensing_time_pathfinder',
        ]);
        addGapPopup(currMap, ais_gaps_with_images_id, popupRef, item.possibility_area_in_gap_at_datatake_sensing_time);
    }
};

const addOilPipelinesLayer = (currMap: mapboxgl.Map, item: PathfinderData) => {
    const oil_pipelines_id = `oil_pipelines_${item.id}`;
    const oil_pipelines_id_hover = oil_pipelines_id + '_hover';
    if (currMap && !currMap.getSource(oil_pipelines_id)) {
        currMap
            .addSource(oil_pipelines_id, {
                type: 'geojson',
                data: item.oil_pipelines,
            })
            .addLayer({
                // This layer is for listening to hovers, needs to have same name as source
                id: oil_pipelines_id,
                type: 'line',
                source: oil_pipelines_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-width': 30,
                    'line-opacity': 0,
                },
            })
            .addLayer({
                // This layer is for looking hovered when layer above is hovered
                id: oil_pipelines_id_hover,
                type: 'line',
                source: oil_pipelines_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-width': [
                        'case',
                        ['==', ['feature-state', 'hover'], true],
                        2, // when hover
                        1, // when normal
                    ],
                    'line-color': [
                        'case',
                        ['==', ['feature-state', 'hover'], true],
                        '#aaa', //'#4d545e', // when hover 6b97dd
                        '#777777', //'#343435', // when normal
                    ],
                },
            });
        addMouseMoveHoverEffect(currMap, oil_pipelines_id);
    }
};

const addCloudmasksLayer = (currMap: mapboxgl.Map, item: PathfinderData) => {
    let cloudmasks_id = `cloudmasks_${item.id}`;
    if (currMap && !currMap.getSource(cloudmasks_id)) {
        currMap
            .addSource(cloudmasks_id, {
                type: 'geojson',
                data: item.cloudmasks,
            })
            .addLayer({
                id: cloudmasks_id,
                type: 'fill',
                source: cloudmasks_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'fill-color': '#c0e0f3',
                    'fill-opacity': 0.2,
                },
                filter: ['==', 'gap_ix', ''],
            });
    }
};

const addPossibilityAreaInGapAtSensingTimeLayer = (currMap: mapboxgl.Map, item: PathfinderData) => {
    let layer_id = `possibility_area_in_gap_at_datatake_sensing_time_${item.id}`;
    if (currMap && !currMap.getSource(layer_id)) {
        currMap
            .addSource(layer_id, {
                type: 'geojson',
                data: item.possibility_area_in_gap_at_datatake_sensing_time,
            })
            .addLayer({
                id: layer_id,
                type: 'fill',
                source: layer_id,
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'fill-color': '#a762a8',
                    'fill-opacity': 0.5,
                },
                filter: ['==', 'gap_ix', ''],
            });
    }
};

const setLayerVisibility = (currMap: mapboxgl.Map, visible: Boolean, layer_id: string) => {
    let visibility = visible ? 'visible' : 'none';
    if (layerExists(currMap, layer_id)) {
        currMap.setLayoutProperty(layer_id, 'visibility', visibility);
        if (layerExists(currMap, `${layer_id}_hover`)) {
            currMap.setLayoutProperty(`${layer_id}_hover`, 'visibility', visibility);
        }
    }
};

export const layerExists = (currMap: mapboxgl.Map, layer: string) => {
    return !!currMap.getLayer(layer);
};
