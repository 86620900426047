import React from 'react';
import styled from 'styled-components';
import { useDetectionImage, useEarthEngineImage, usePlanetThumbnail } from '../../requests/image';
import { ReportType } from '../../utils/enums';
import { prodUrl } from '../../utils/env';
import { ButtonLink } from '../styles/Buttons';
import { Spinner } from '../styles/Spinner';

const PopupDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    min-width: 220px;
`;

const SpinnerWrapper = styled.div`
    width: 100%;
    height: 120px;
`;

const StyledImage = styled.img`
    align-self: center;
    width: 100%;
`;

const IdentificationButton = styled.a`
    width: 100px;
    padding: 8px;
    color: black;
    background-color: #006099;
    border-radius: 10px;
    text-align: center;
    margin-top: 5px;
`;

const LinkInPopup = styled(ButtonLink)`
    color: white;
    padding: 2%;
    align-self: center;
`;

export const DetectionImage: React.FC<{ image_path: any }> = ({ image_path }) => {
    const { data, isFetching } = useDetectionImage(image_path);

    return (
        <>
            {isFetching ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : (
                <StyledImage src={data} />
            )}
        </>
    );
};

export const PlanetThumbnail: React.FC<{ image_path: any }> = ({ image_path }) => {
    const { data, isFetching } = usePlanetThumbnail(image_path.split('/items/')[1]);
    return (
        <>
            {isFetching ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : (
                <StyledImage src={data} />
            )}
        </>
    );
};

export const TargetOfInterestImage: React.FC<{ feature: any }> = ({ feature }) => {
    let image_source = feature.image_source || 'sentinel2';

    const { data, isFetching } = useEarthEngineImage(
        feature.lon,
        feature.lat,
        new Date(feature.timestamp),
        image_source
    );

    return (
        <>
            {isFetching ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : (
                <StyledImage src={data} />
            )}
        </>
    );
};

function ConvertDDToDMS(D: any) {
    return [0 | D, '° ', 0 | (((D = (D < 0 ? -D : D) + 1e-4) % 1) * 60), "' ", 0 | (((D * 60) % 1) * 60), '"'].join('');
}

export const ReportPopup: React.FC<{ feature: any; reportType: ReportType; isAdmin?: boolean }> = ({
    feature,
    reportType,
    isAdmin,
}) => {
    const {
        image_source,
        timestamp,
        // correlated,
        object_class,
        // matching_label,
        length,
        lon,
        lat,
        object_class_score,
        ais_length,
        // estimation_score,
        // matching_score,
        course,
        speed,
        mmsi,
        image_path,
        // distance_to_mmsi,
        // ais_length,
        // time_to_nearest_ais,
    } = feature;
    let formattedTimestamp = new Date(timestamp).toUTCString(); //.substring(17);
    if (reportType === ReportType.InteractiveReport) {
        formattedTimestamp = timestamp.slice(11, 19);
    }
    return (
        <PopupDiv>
            <div onClick={() => console.log(lat + ', ' + lon)}>
                <b>Satellite:</b> {image_source && capitalizeFirstLetter(image_source)}
            </div>
            {lon && (
                <div>
                    <b>Longitude:</b> {ConvertDDToDMS(lon)}
                </div>
            )}
            {lat && (
                <div>
                    <b>Latitude:</b> {ConvertDDToDMS(lat)}
                </div>
            )}
            {object_class && (
                <div>
                    <b>Class:</b> {object_class && capitalizeFirstLetter(object_class)}
                </div>
            )}
            {object_class_score && (
                <div>
                    <b>Certainty:</b> {Number(object_class_score).toFixed(2)}
                </div>
            )}
            {mmsi && (
                <div>
                    <b>MMSI:</b> {mmsi}
                </div>
            )}
            {course && (
                <div>
                    <b>{mmsi ? 'AIS Course' : 'Course'}:</b> {course && Number(course).toFixed()}°
                </div>
            )}
            {speed && (
                <div>
                    <b>AIS Speed:</b> {speed && Number(speed).toFixed(1)} kn
                </div>
            )}
            {length && (
                <div>
                    <b>Predicted Length:</b> {Number(length).toFixed()} m
                </div>
            )}
            {ais_length && (
                <div>
                    <b>AIS Length:</b> {Number(ais_length).toFixed()} m
                </div>
            )}
            <div>
                <b>Timestamp:</b> {formattedTimestamp}
            </div>
            {image_path ? (
                <DetectionImage image_path={image_path} />
            ) : (
                <TargetOfInterestImage feature={{ ...feature }} />
            )}
            {image_path && isAdmin ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IdentificationButton
                        href={
                            prodUrl +
                            '/clearview?uuid=' +
                            JSON.stringify([image_path.split('sentinel2/')[1].split('.png')[0]])
                        }
                        target={'_blank'}
                    >
                        Identify
                    </IdentificationButton>
                </div>
            ) : null}
        </PopupDiv>
    );
};

export const PropertiesPopup: React.FC<{ feature: any; reportType: ReportType }> = ({ feature, reportType }) => {
    return (
        <PopupDiv>
            {Object.entries(feature)
                .filter(([key, value]) => value)
                .map(([key, value]) => (
                    <div key={key} onClick={() => console.log(feature.lat, ', ', feature.lon)}>
                        <b>{capitalizeFirstLetter(formatKey(key))}:</b> {formatValue(key, value, reportType)}
                    </div>
                ))}
        </PopupDiv>
    );
};

export const GapPopup: React.FC<{ clearviewLink: string }> = ({ clearviewLink }) => {
    return (
        <PopupDiv>
            <LinkInPopup href={clearviewLink} target="_blank" background={'#750cf5'}>
                Locate dark vessel in gap with VAKE ClearView
            </LinkInPopup>
        </PopupDiv>
    );
};

export const TargetsOfInterestPopup: React.FC<{ feature: any; reportType: ReportType }> = ({ feature, reportType }) => {
    return (
        <PopupDiv>
            {Object.entries(feature)
                .filter(([key, value]) => value)
                .filter(([key, value]) => !['object_class_score', 'uuid'].includes(key))
                .map(([key, value]) => (
                    <div key={key}>
                        <b>{capitalizeFirstLetter(formatKey(key))}:</b> {formatValue(key, value, reportType)}
                    </div>
                ))}
            {feature.image_path ? (
                <DetectionImage image_path={feature.image_path} />
            ) : (
                <TargetOfInterestImage feature={{ ...feature }} />
            )}
        </PopupDiv>
    );
};

export const TrackPopup: React.FC<{ feature: any }> = ({ feature }) => {
    const { mmsi, start_timestamp, end_timestamp } = feature;
    return (
        <PopupDiv>
            {mmsi && (
                <div>
                    <b>MMSI:</b> {mmsi}
                </div>
            )}
            {start_timestamp && (
                <div>
                    <b>Start:</b> {start_timestamp}
                </div>
            )}
            {end_timestamp && (
                <div>
                    <b>End:</b> {end_timestamp}
                </div>
            )}
        </PopupDiv>
    );
};

const formatKey = (key: string) => {
    key = key === 'image_source' ? 'Image source' : key;
    key = key === 'correlated' ? 'Correlation' : key;
    key = key === 'object_class' ? 'Object class' : key;
    key = key === 'matching_label' ? 'Status' : key;
    return key;
};

const formatValue = (key: string, value: any, reportType?: ReportType) => {
    value = key === 'timestamp' && reportType === ReportType.InteractiveReport ? value.slice(11, 19) : value;
    value = key === 'length' ? value && Number(value).toFixed(2) + ' m' : value;
    value = key === 'probability' ? Number(value) + ' %' : value;
    value = key === 'image_source' ? (value === 'sentinel1' ? 'Sentinel-1' : 'Sentinel-2') : value;
    value = key === 'time_to_nearest_ais' ? value && Number(value).toFixed(1) + ' s' : value;
    value = key === 'course' ? value && Number(value).toFixed() + '°' : value;
    value = key === 'correlated' ? (value ? 'Done' : 'Not done') : value;
    value = key === 'speed' ? Number(value) + ' kn' : value;
    return value;
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);
